@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    padding: 0;
    margin: 0;
    @apply w-full;
    @apply min-h-screen;
    background-color: theme(colors.primary.contrast);
    position: relative;
  }
  /* latin regular */
  @font-face {
    font-family: 'Noto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/NotoSans-Regular.ttf');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin bold */
  @font-face {
    font-family: 'Noto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./assets/fonts/NotoSans-Bold.ttf');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* arabic regular */
  @font-face {
    font-family: 'Noto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/NotoKufiArabic-Regular.ttf');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* arabic bold */
  @font-face {
    font-family: 'Noto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./assets/fonts/NotoKufiArabic-Bold.ttf');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
}

div[id="root"] {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

html {
  -webkit-tap-highlight-color: transparent;
}